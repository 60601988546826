import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Layout
import Header from "../../layout/header";
import Footer from "../../layout/footer";

// Elements
import WidgetBrochure from "../../elements/widget/widget-brochure";
import WidgetHelp from "../../elements/widget/widget-help";
import ScheduleServiceSection from "../../elements/schedule-service";
import TestimonialSection from "../../elements/testimonial";
import InstagramBox from "../../elements/widget/InstagramBox";
import OurBrandsLayout from "../../elements/widget/OurBrandsLayout";
import BrandServices from "../../elements/widget/BrandServices";

// Images
import bnrImg from "../../../images/carbrands/Maserati.webp";
import serviceSectionPic3 from "../../../images/brandsthumb/MASERATI body Image.webp";

function Maybach() {
  const { t } = useTranslation();
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
                <span>{t("brandmaybach.headtitle")}</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="las la-home"></i>{t('header-area.home')}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/ourbrands">{t('header-area.brands')}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  {t("brandmaybach.name")}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="section-area section-sp2 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-5">
                <aside className="sticky-top pb-1">
                  <WidgetBrochure />

                  <WidgetHelp />
                </aside>
              </div>
              <div className="col-lg-8 col-md-7">
                <div className="row">
                  <div className="col-xl-6">
                    <div className="ttr-media mb-30">
                      <img src={serviceSectionPic3} alt="" />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <h4 className="title mb-10">
                      {t("brandmaybach.benefitsH1")}
                    </h4>
                    <p className="mb-20">{t("brandmaybach.bsubtext")}</p>
                    <ul className="list-check-squer mb-30">
                      <li>{t("brandmaybach.blist1")}</li>
                      <li>{t("brandmaybach.blist2")}</li>
                      <li>{t("brandmaybach.blist3")}</li>
                      <li>{t("brandmaybach.blist4")}</li>
                      <li>{t("brandmaybach.blist5")}</li>
                    </ul>
                  </div>
                </div>

                <div className="mb-10 ContentBox2">
                  <div className="head-text mb-30">
                    <h4 className="title mb-15">
                      {t("brandmaybach.headtext1")}
                    </h4>
                    <h5 className="mb-15">
                      <b>{t("brandmaybach.subhead1")}</b>
                    </h5>
                    <p className="mb-15">{t("brandmaybach.content1")}</p>
                    <p className="mb-15">{t("brandmaybach.content2")}</p>
                    <p className="mb-15">{t("brandmaybach.content3")}</p>
                    <p className="mb-15">{t("brandmaybach.content4")}</p>
                    <h5 className="mb-15">
                      <b>{t("brandmaybach.subhead2")}</b>
                    </h5>
                    <p className="mb-15">{t("brandmaybach.content5")}</p>
                    <p className="mb-15">{t("brandmaybach.content6")}</p>
                  </div>
                  <div className="row">
                    <h5 className="mb-15">
                      <b>{t("brandmaybach.subhead3")}</b>
                    </h5>
                    <div className="col-xl-12">
                      <ul className="list-check-squer">
                        <li>{t("brandmaybach.slist1")}</li>
                        <li>{t("brandmaybach.slist2")}</li>
                        <li>{t("brandmaybach.slist3")}</li>
                        <li>{t("brandmaybach.slist4")}</li>
                        <li>{t("brandmaybach.slist5")}</li>
                        <li>{t("brandmaybach.slist6")}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="head-text mb-30">
                  <h4 className="title mb-10">{t("brandmaybach.headtext2")}</h4>
                  <p className="mb-15">{t("brandmaybach.content7")}</p>
                  <p className="mb-15">{t("brandmaybach.content8")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section-area section-sp2 ServiceBox1 IconBox1">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary arhide">
              {t("brandmaybach.servicessubhead")}
            </h6>
            <h2 class="title mb-0">{t("brandmaybach.serviceshead")}</h2>
            <p>{t("brandmaybach.servicescontent")}</p>
          </div>
          
          <BrandServices brname={'maybach'} />

        </div>
      </section>
      <section className="section-area BrandFaq mbg1">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary">{t("header-area.swiss")}</h6>
            <h2 class="title mb-0">{t("header-area.faq")}</h2>
            <p>
            {t("header-area.faqtext")}
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("brandmaybach.question1")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("brandmaybach.answer1")}</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("brandmaybach.question2")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("brandmaybach.answer2")}</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("brandmaybach.question3")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">Your {t("brandmaybach.answer3")}</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("brandmaybach.question4")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("brandmaybach.answer4")}</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <ScheduleServiceSection />
      <TestimonialSection />
      <OurBrandsLayout />
      <InstagramBox />
      <Footer />
    </>
  );
}

export default Maybach;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AboutUsTwoSection from "../elements/about-us-2";
import ServicesSection from "../elements/services-1";
import ScheduleServiceSection from "../elements/schedule-service";
import TestimonialSection from "../elements/testimonial";
import LatestBlogSection from "../elements/latest-blog-slider";

// Images
import bnrImg from "../../images/banner/aboutus.jpg";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet";

function AboutUs() {

  const { t } = useTranslation();

  return (
    <>

      <Header />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
                <span>{t('aboutpage.about-title')}</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="las la-home"></i>{t('header-area.home')}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  {t('aboutpage.about-title')}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className=" AbtBx1">
          <div className="container">
            <div class="heading-bx text-center">
              <h6 class="title-ext text-primary">{t('aboutpage.swiss')}</h6>
              <h2 class="title mb-0">
              {t('aboutpage.headtext')}
              </h2>
              <p>{t('aboutpage.subtext1')}</p>
              <p>{t('aboutpage.subtext2')}</p>
              <p>{t('aboutpage.subtext3')}</p>
              <p>{t('aboutpage.subtext4')}</p>
            </div>
          </div>
        </div>
		<div className=" AbtBx2">
          <div className="container">
            <div class="heading-bx text-center">
              <h6 class="title-ext text-primary">{t('aboutpage.swiss')}</h6>
              <h2 class="title mb-0">
              {t('aboutpage.mission')}
              </h2>
              <p>
                {t('aboutpage.missiontext')}
              </p>
            </div>
          </div>
        </div>
		<div className=" AbtBx1">
          <div className="container">
            <div class="heading-bx text-center">
              <h6 class="title-ext text-primary">{t('aboutpage.swiss')}</h6>
              <h2 class="title mb-0">
              {t('aboutpage.vision')}
              </h2>
              <p>
              {t('aboutpage.visiontext')}
              </p>
            </div>
          </div>
        </div>
		<section className="section-area section-sp2 ServiceBox1">
          <div className="container">
            <div class="heading-bx text-center">
              <h6 class="title-ext text-primary">{t('aboutpage.facility')}</h6>
              <h2 class="title mb-0">{t('aboutpage.facility-head')}</h2>
              <p>
              {t('aboutpage.facility-subtext')}
              </p>
            </div>
            <div className="SerGridWrap">
              <div className="row mb-10">
                <div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <i class="flaticon-car-4"></i>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list1')}</h5>
                  </div>
                </div>
				<div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <i class="flaticon-car-4"></i>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list2')}</h5>
                  </div>
                </div>
				<div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <i class="flaticon-car-4"></i>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list3')}</h5>
                  </div>
                </div>
				<div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <i class="flaticon-car-4"></i>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list4')}</h5>
                  </div>
                </div>
				<div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <i class="flaticon-car-4"></i>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list5')}</h5>
                  </div>
                </div>
				<div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <i class="flaticon-car-4"></i>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list6')}</h5>
                  </div>
                </div>
				<div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <i class="flaticon-car-4"></i>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list7')}</h5>
                  </div>
                </div>
				<div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <i class="flaticon-car-4"></i>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list8')}</h5>
                  </div>
                </div>
				<div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <i class="flaticon-car-4"></i>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list9')}</h5>
                  </div>
                </div>
				<div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <i class="flaticon-car-4"></i>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list10')}</h5>
                  </div>
                </div>
				<div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <i class="flaticon-car-4"></i>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list11')}</h5>
                  </div>
                </div>
				<div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <i class="flaticon-car-4"></i>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list12')}</h5>
                  </div>
                </div>
				<div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <i class="flaticon-car-4"></i>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list13')}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <TestimonialSection />
        <ScheduleServiceSection />
      </div>

      <Footer />
    </>
  );
}

export default AboutUs;
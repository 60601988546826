import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Elements
import Count from '../elements/counter-sensor';

// Images
import bg1 from "../../images/background/bg1.png";
import aboutPic1 from "../../images/about/about2/500x650.webp";
import aboutPic2 from "../../images/about/about2/500x511.webp";

function AboutUsFourSection(){
	
	const { t } = useTranslation();

		return(
			<>
				<section className="section-area about-wraper1 bg-gray section-sp2" style={{backgroundImage: "url("+bg1+")", backgroundRepeat: "no-repeat"}}>
					<div className="container">
						<div className="row">
							<div className="col-lg-6 about-media mb-30">
								<div className="about-img1">
									<img src={aboutPic1} alt=""/>
								</div>
								<div className="about-img2">
									<img src={aboutPic2} alt=""/>
								</div>
								<div className="experience-bx">
									<h4 className="exp-text">
										<span className="counter"><Count counter={20}/></span> +
									</h4>
									<h6 className="exp-year">{t('home-about-section.experience')}</h6>
								</div>
							</div>
							<div className="col-lg-6 mb-30">
								<div className="heading-bx">
									<h6 className="title-ext m-b0 text-primary">{t('home-about-section.title')}</h6>
									<h2 className="title mb-0">{t('home-about-section.subtitle')}</h2>
									<p className="fw6 fs18">{t('home-about-section.excert')}</p>
								</div>
								<ul className="list-check-squer mb-50 mb-sm-30">
									<li>{t('home-about-section.list1')}</li>
									<li>{t('home-about-section.list2')}</li>
									<li>{t('home-about-section.list3')}</li>
									<li>{t('home-about-section.list4')}</li>
								</ul>
								<Link to="/aboutus" className="btn btn-primary btn-lg">{t('home-about-section.btn')}</Link>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	
}

export default AboutUsFourSection;
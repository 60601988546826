import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Layout
import Header from "../../layout/header";
import Footer from "../../layout/footer";

// Elements
import WidgetBrochure from "../../elements/widget/widget-brochure";
import WidgetHelp from "../../elements/widget/widget-help";

// Images
import bnrImg from "../../../images/newimages/package-banner.webp";
import Pic1 from "../../../images/service-section/pic1.jpg";
import axios from "axios";
import { Helmet } from "react-helmet";

function ServiceContract() {
  const { t } = useTranslation();
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
                <span>{t("servicecontract.titleH1")}</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="las la-home"></i>
                      {t("header-area.home")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("servicecontract.titleH1")}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="section-area section-sp1 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-5">
                <aside className="sticky-top pb-1">
                  <WidgetBrochure />

                  <WidgetHelp />
                </aside>
              </div>
              <div className="col-lg-8 col-md-7">
                <div className="mb-10">
                  <div className="head-text mb-30">
                    <h4 className="title mb-15">
                      {t("servicecontract.titleH4-1")}
                    </h4>
                    <p className="mb-15">{t("servicecontract.subtext1")}</p>
                    <p className="mb-15">{t("servicecontract.subtext2")}</p>

                    <h4 className="title mb-15 text-uppercase">
                      {t("servicecontract.titleH4-2")}
                    </h4>
                    <p className="mb-15">{t("servicecontract.subtext3")}</p>
                    <p className="mb-15">{t("servicecontract.subtext4")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section-area section-sp2 ServiceBox1">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary text-uppercase">
              {t("servicecontract.whychoose")}
            </h6>
            <h2 class="title mb-0 text-uppercase">
              {t("servicecontract.whychooseH2")}
            </h2>
          </div>
          <div className="SerGridWrap">
            <div className="row mb-10 justify-content-center">
              <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                <div className="counter-style-1">
                  <div className="text-secondry">
                    <h2 className="text-primary">
                      <span className="counter">
                        <i class="flaticon-car-4"></i>
                      </span>
                    </h2>
                  </div>
                  <h5 className="counter-text">{t("servicecontract.list1")}</h5>
                  <p>{t("servicecontract.listText1")}</p>
                </div>
              </div>
              <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                <div className="counter-style-1">
                  <div className="text-secondry">
                    <h2 className="text-primary">
                      <span className="counter">
                        <i class="flaticon-car-4"></i>
                      </span>
                    </h2>
                  </div>
                  <h5 className="counter-text">{t("servicecontract.list2")}</h5>
                  <p>{t("servicecontract.listText2")}</p>
                </div>
              </div>
              <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                <div className="counter-style-1">
                  <div className="text-secondry">
                    <h2 className="text-primary">
                      <span className="counter">
                        <i class="flaticon-car-4"></i>
                      </span>
                    </h2>
                  </div>
                  <h5 className="counter-text">{t("servicecontract.list3")}</h5>
                  <p>{t("servicecontract.listText3")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-area BrandFaq">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary">{t("header-area.swiss")}</h6>
            <h2 class="title mb-0">{t("header-area.faq")}</h2>
            <p>{t("header-area.faqtext")}</p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("servicecontract.question1")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("servicecontract.answer1")}</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("servicecontract.question2")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("servicecontract.answer2")}</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("servicecontract.question3")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("servicecontract.answer3")}</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default ServiceContract;

import React, {  useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

import SwissBrands from '../elements/widget/Swiss-Brands';

// Images
import logo from "../../images/Logo_dark_147x54.webp";
import logoWhite from "../../images/Logo_light_147x54.webp";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import axios from 'axios';
import { Helmet } from 'react-helmet';



function AboutSection(){


  const [data, setData] = useState();

  
  const pathname = window.location.pathname;
  const canonical = "https://www.swissauto.me"+pathname;
  //console.log(pathname, "llll");
	
  useEffect(() => {
    axios.get(`https://swiss-backend.vercel.app/api/meta`).then((response) => {
      const meta = response.data.data.filter((i) => i.name === pathname);
      setData(meta[0]);
      //console.log(meta[0]);
    });
  }, [pathname]);

	
	const { t } = useTranslation();



	const handleRefresh = (url) => {
		window.location.href = url;
	};

	const currentLanguage = i18next.language; // "en" or "ar"

	
	if (currentLanguage === "en") {
	// Render English content
	//console.log("en");
	} else if (currentLanguage === "ar") {
	// Render Arabic content
	//console.log("ar");
	}
		
	useEffect(() => {
	
		// Mobile Menu Open
		var menuIcon = document.querySelector(".menuicon")
		var menuLinks = document.querySelector(".menu-links")
		var menuClose = document.getElementById("menuClose")
		var menuDeskOpen = document.getElementById("HamberMenuClick")
		var menuDeskClose = document.getElementById("HamberMenuClose")
		var panelClose1 = document.getElementById("PanelCloseBtn")
		var ClickedNav = document.getElementById("ClickedNav")
		
		ClickedNav.addEventListener('click',function(){
			document.body.classList.remove("ActivePanel");
		})
		panelClose1.addEventListener('click',function(){
			document.body.classList.remove("ActivePanel");
		})
		
		menuDeskOpen.addEventListener('click',function(){
			document.body.classList.add("ActivePanel");
		})
		
		menuDeskClose.addEventListener('click',function(){
			document.body.classList.remove("ActivePanel");
		})
		
		menuIcon.addEventListener('click',function(){
			menuLinks.classList.add("show");
			document.body.classList.add("No-index");
		})

		menuClose.addEventListener('click',function(){
			menuLinks.classList.remove("show");;
			document.body.classList.remove("No-index");
		})
		
		// Mobile Submenu Open Close Function
		var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
		for (var y = 0; y < navMenu.length; y++) {
			navMenu[y].addEventListener('click', function () { menuClick(this) });
		}
	
		function menuClick(current) {
			const active = current.classList.contains("open")
			navMenu.forEach(el => el.classList.remove('open'));
			
			if(active){
				current.classList.remove('open') 
				//console.log("active")
			} else{
				current.classList.add('open');
				//console.log("close")
			}
		}

		
	}, [])

	
		return(
			<>
				<Helmet>
					<title>{data?.title}</title>
					<meta name="description" content={data?.content} />
					<link rel="canonical" href={canonical} />
					<body dir={currentLanguage === "ar" && "rtl"} className={currentLanguage === "ar" ? "rtl" : "ltl"}></body>
				</Helmet>
				<header className="header header-transparent rs-nav">
					<div className='announcement-bar desk-only'>
						<div className='container'>
							<div className='row'>
								<div className='col-md-5'>{t('header-area.headtag')}</div>
								<div className='col-md-7'>
									<div className='AnnouncementIcons'>
										<a href='tel:966557835647' className='AnnonceLink1 call' dir={currentLanguage === "ar" && "ltr"}>
										{t('header-area.phoneno')}

										</a>
										<a href='https://wa.me/966557835647?text=Hi%2C%20Swiss%20Auto%20Services' className='AnnonceLink1 whatsapp'>
										{t('header-area.whatsapp')}
										</a>
										<a href='https://maps.app.goo.gl/2uqjH5UVYeofASdt9' className='AnnonceLink1 location'>
										{t('header-area.direction')}
										</a>
										<a href='mailto:hello@swissauto.me' className='AnnonceLink1 email'>
										hello@swissauto.me
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="menudrop " id='PanelCloseBtn'>&nbsp;</div>
					<div class="SideNav">
						<div class="SideNavContent">
							<span class="CloseClick">
								<span class="CloseBtn1" id='HamberMenuClose'>&nbsp;</span>
							</span>
							<div class="NavBox" id='ClickedNav'>
								<Link to="/">{t('header-area.home')}</Link>
								<Link to="/aboutus">{t('header-area.about')}</Link>
								<Link to="/booking">{t('header-area.bookapp')}</Link>
								{currentLanguage === "en" && 
								<a href="https://blogs.swissauto.me">{t('header-area.blogs')}</a>}
								{currentLanguage === "ar" && 
								<a href="https://blogs.swissauto.me/ar">{t('header-area.blogs')}</a>}
								<Link to="/services">{t('header-area.ourservices')}</Link>
								<Link to="/contact">{t('header-area.contact')}</Link>
							</div>
							<div className='SideNavSocial widget '>
							<ul className="list-inline ft-social-bx">
											<li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/swissautosa/" className="btn button-sm"><i className="fa fa-facebook"></i></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/swissautosa/" className="btn button-sm"><i className="fa fa-instagram"></i></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCIDHmzSK6mFQykqrG_SjVig" className="btn button-sm"><i className="fa fa-youtube"></i></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://wa.me/966557835647?text=Hi%2C%20Swiss%20Auto%20Services" className="btn button-sm"><i className="fa fa-whatsapp"></i></a></li>
										</ul>
							</div>
						</div>
					</div>
		
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						
						<div className="menu-bar clearfix ">
							<div className="container clearfix">
								<div className="menu-logo">
									<Link to="/" className="main-logo"><img src={logoWhite} alt=""/></Link>
									<Link to="/" className="sticky-logo"><img src={logo} alt=""/></Link>
								</div>
								
								<button className="navbar-toggler collapsed menuicon justify-content-end"  type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>

								<div className="mobileLanguage">
										<div onClick={()=>handleRefresh(currentLanguage=== "en" ? "/ar/" : "/en/")}  className="btn btn-language">{currentLanguage === "en" ? "عربي" : "English"}<span className="flag1">&nbsp;</span></div>
								</div>
								
								<div className="secondary-menu">
									<div onClick={()=>handleRefresh(currentLanguage=== "en" ? "/ar/" : "/en/")}  className="btn btn-language">{currentLanguage === "en" ? "عربي" : "English"}<span className="flag1">&nbsp;</span></div>
									<Link to="/booking" className="btn btn-primary ms-3">{t('header-area.bookapp')}</Link>
									<div className='HamberMenuClick  desk-only' id='HamberMenuClick'>&nbsp;</div>
								</div>
								<div className="menu-links navbar-collapse collapse justify-content-center" id="menuDropdown">
									<div className="menu-logo">
										<Link to="/"><img src={logoWhite} alt=""/></Link>
									</div>
									<ul className="nav navbar-nav">	
										
										<li className="has-mega-menu "><Link to="/ourbrands">{t('header-area.brands')}<i className="fa fa-chevron-down"></i></Link>
											<div className="mega-menu">
												<SwissBrands/>
											</div>
										</li>
										<li className="has-mega-menu "><Link to="/services">{t('header-area.services')}<i className="fa fa-chevron-down"></i></Link>
											<ul className="mega-menu">
												<li>
													<Link to="/services">{t('header-area.mech')}</Link>
													<ul>
														<li><Link to="/services/mechanical-repair/axle-drift-shaft-repair"><span>{t('header-area.axle')}</span></Link></li>
														<li><Link to="/services/mechanical-repair/brake-repair"><span>{t('header-area.brake')}</span></Link></li>
														<li><Link to="/services/mechanical-repair/engine-cooling-system-repair"><span>{t('header-area.engilecool')}</span></Link></li>
														<li><Link to="/services/mechanical-repair/gear-box-repair"><span>{t('header-area.gearbox')}</span></Link></li>
														<li><Link to="/services/mechanical-repair/pre-purchase-inspection"><span>{t('header-area.prepurchase')}</span></Link></li>
														<li><Link to="/services/mechanical-repair/suspension-repair"><span>{t('header-area.suspension')}</span></Link></li>
														<li><Link to="/services/mechanical-repair/transmission-repair"><span>{t('header-area.transmission')}</span></Link></li>
														<li><Link to="/services/mechanical-repair/touchless-wheel-alignment"><span>{t('header-area.touchless')}</span></Link></li>
													</ul>
												</li>
												<li>
													<Link to="/services">{t('header-area.bodyshop')}</Link>
													<ul>
														<li><Link to="/services/body-shop/smart-body-repair"><span>{t('header-area.smartbody')}</span></Link></li>
														<li><Link to="/services/body-shop/car-detailing"><span>{t('header-area.cardetail')}</span></Link></li>
														<li><Link to="/services/body-shop/car-tinting"><span>{t('header-area.cartint')}</span></Link></li>
														<li><Link to="/services/body-shop/car-dip-color"><span>{t('header-area.cardip')}</span></Link></li>
														<li><Link to="/services/body-shop/ceramic-coating"><span>{t('header-area.ceramic')}</span></Link></li>
														<li><Link to="/services/body-shop/brake-caliper-painting"><span>{t('header-area.brakecaliper')}</span></Link></li>
														<li><Link to="/services/body-shop/paint-protection-film"><span>{t('header-area.paint')}</span></Link></li>
														<li><Link to="/services/body-shop/wind-shield-repair"><span>{t('header-area.windshield')}</span></Link></li>
														{/* <li><Link to="/services/body-shop/classic-car-restoration"><span>Classic Car Restoration</span></Link></li> */}
													</ul>
												</li>
												<li>
													<Link to="/services">{t('header-area.electrical')}</Link>
													<ul>
														<li><Link to="/services/electrical-repair/ac-repair"><span>{t('header-area.acrepair')}</span></Link></li>
														<li><Link to="/services/electrical-repair/camera-radar-calibration"><span>{t('header-area.camera')}</span></Link></li>
														<li><Link to="/services/electrical-repair/car-software-repair-programming"><span>{t('header-area.carsoft')}</span></Link></li>
														<li><Link to="/services/electrical-repair/comfort-system-repair"><span>{t('header-area.comfort')}</span></Link></li>
														<li><Link to="/services/electrical-repair/engine-control-unit-repair"><span>{t('header-area.enginecontrol')}</span></Link></li>
														{/* <li><Link to="/services/electrical-repair/navigation-update"><span>Navigation Update</span></Link></li>
														<li><Link to="/services/electrical-repair/retrofit"><span>Retrofit</span></Link></li> */}
													</ul>
												</li>
												
												<li>
													<Link to="/services">{t('header-area.othservices')}</Link>
													<ul>
														<li><Link to="/services/other-services/car-recovery"><span>{t('header-area.carrecovery')}</span></Link></li>
														<li><Link to="/services/other-services/fleet-maintenance"><span>{t('header-area.fleet')}</span></Link></li>
														<li><Link to="/services/other-services/electric-car-repair"><span>{t('header-area.electriccar')}</span></Link></li>
														
													</ul>
												</li>
											</ul>
										</li>
										<li>
											<Link to="/packages">{t('header-area.ourpackage')}<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li className="add-menu-left">
													<ul>
														<li><Link to="/packages/service-contract"><span>{t('header-area.servicecontract')}</span> </Link></li>
														<li><Link to="/packages/major-service"><span>{t('header-area.majorservice')}</span></Link></li>
														<li><Link to="/packages/minor-service"><span>{t('header-area.minorservice')}</span></Link></li>
													</ul>
												</li>
											</ul>
										</li>
										<li><Link to="/aboutus">{t('header-area.who')} <i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu left">
												<li><Link to="/aboutus"><span>{t('header-area.about')}</span></Link></li>
												<li><Link to="/careers"><span>{t('header-area.careers')}</span></Link></li>
												</ul>
										</li>
										<li className='mob-only'>
											
											<Link to="/booking">{t('header-area.bookapp')} <i className="fa fa-chevron-down"></i></Link>
											
										</li>
										<li>
											{currentLanguage === "en" && 
											<a href="https://blogs.swissauto.me">{t('header-area.blogs')}</a>}
											{currentLanguage === "ar" && 
											<a href="https://blogs.swissauto.me/ar">{t('header-area.blogs')}</a>}
											
										</li>
										<li>
											
											<Link to="/offers">{t('header-area.offers')}</Link>
											
										</li>
										
										<li className='mob-only'>
											
											<Link to="/contactus">{t('header-area.contact')}<i className="fa fa-chevron-down"></i></Link>
											
										</li>
									</ul>
									<ul className="social-media">
											<li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/swissautosa/" className="btn button-sm"><i className="fa fa-facebook"></i></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/swissautosa/" className="btn button-sm"><i className="fa fa-instagram"></i></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCIDHmzSK6mFQykqrG_SjVig" className="btn button-sm"><i className="fa fa-youtube"></i></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://wa.me/966557835647?text=Hi%2C%20Swiss%20Auto%20Services" className="btn button-sm"><i className="fa fa-whatsapp"></i></a></li>
										</ul>
									<div className="menu-close" id="menuClose">
										<i className="ti-close"></i>
									</div>
								</div>
							</div>
						</div>
					</Sticky>
				</header>
			
			</>
		);
}

export default AboutSection;
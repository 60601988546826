import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

// Layout
import Header from "../../../layout/header";
import Footer from "../../../layout/footer";

// Elements
import WidgetBrochure from "../../../elements/widget/widget-brochure";
import WidgetHelp from "../../../elements/widget/widget-help";
import ScheduleServiceSection from "../../../elements/schedule-service";
import TestimonialSection from "../../../elements/testimonial";
import ChooseUsSection from "../../../elements/choose-us";
import ServicesSection from "../../../elements/services-1";

// Images
import bnrImg from "../../../../images/services/PaintJob.webp";
import serviceSectionPic3 from "../../../../images/service-section/pic3.jpg";

function BreakCaliperPainting() {

  const { t } = useTranslation();

    return (
      <>
        <Header />

          <div className="page-content bg-white">
            <div
              className="page-banner ovbl-dark"
              style={{ backgroundImage: "url(" + bnrImg + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>
                    <span>{t('header-area.brakecaliper')}</span>
                  </h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="las la-home"></i>{t('header-area.home')}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                          {t('header-area.brakecaliper')}
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div className="section-area section-sp1 bg-white">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-5">
                    <aside className="sticky-top pb-1">
                      <WidgetBrochure />

                      <WidgetHelp />
                    </aside>
                  </div>
                  <div className="col-lg-8 col-md-7">
                    <div className="mb-10">
                      <div className="head-text mb-30">
                        <h4 className="title mb-15">{t('BreakCaliperPainting.titleH4-1')}
                        </h4>
                        <p className="mb-15">
                            {t('BreakCaliperPainting.paraText1')}
                        </p>
                        <p className="mb-15">
                            {t('BreakCaliperPainting.paraText2')}
                        </p>

                        <p className="mb-15">
                            {t('BreakCaliperPainting.paraText3')}
                        </p>
                      <h4 className="title mb-15 text-uppercase">
                          {t('BreakCaliperPainting.titleH4-2')}
                        </h4>
                        <ul className="list-check-squer mb-15">
                          <li>
                            {t('BreakCaliperPainting.blist1')}
                          </li>
                          <li>
                              {t('BreakCaliperPainting.blist2')}
                          </li>
                          <li>
                              {t('BreakCaliperPainting.blist3')}
                          </li>
                          <li>{t('BreakCaliperPainting.blist4')}</li>
                        </ul>
                        <h4 className="title mb-10 text-uppercase">
                            {t('BreakCaliperPainting.titleH4-3')}
                        </h4>
                        <p className="mb-15">
                            {t('BreakCaliperPainting.paraText4')}
                        </p>
                        <h4 className="title mb-10 text-uppercase">
                            {t('BreakCaliperPainting.titleH4-4')}
                        </h4>
                        <p className="mb-15">{t('BreakCaliperPainting.paraText5')}</p>
                          <p className="mb-15">{t('BreakCaliperPainting.paraText6')}</p>
                      </div>
                    </div>
                    
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="section-area section-sp2 ServiceBox1">
            <div className="container">
              <div class="heading-bx text-center">
                <h6 class="title-ext text-primary arhide">{t('BreakCaliperPainting.benefitsHead')}</h6>
                <h2 class="title mb-0">
                    {t('BreakCaliperPainting.bsubtext')}
                </h2>
              </div>
              <div className="SerGridWrap">
                <div className="row mb-10 justify-content-center">
                  <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                    <div className="counter-style-1">
                      <div className="text-secondry">
                        <h2 className="text-primary">
                          <span className="counter">
                            <i class="flaticon-car-4"></i>
                          </span>
                        </h2>
                      </div>
                      <h5 className="counter-text">
                        {t('BreakCaliperPainting.benefitslist1')}
                      </h5>
                      <p>
                        {t('BreakCaliperPainting.benefitslistText1')}
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                    <div className="counter-style-1">
                      <div className="text-secondry">
                        <h2 className="text-primary">
                          <span className="counter">
                            <i class="flaticon-car-4"></i>
                          </span>
                        </h2>
                      </div>
                      <h5 className="counter-text">{t('BreakCaliperPainting.benefitslist2')}</h5>
                      <p>
                          {t('BreakCaliperPainting.benefitslistText2')}
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                    <div className="counter-style-1">
                      <div className="text-secondry">
                        <h2 className="text-primary">
                          <span className="counter">
                            <i class="flaticon-car-4"></i>
                          </span>
                        </h2>
                      </div>
                      <h5 className="counter-text">{t('BreakCaliperPainting.benefitslist3')}</h5>
                      <p>
                          {t('BreakCaliperPainting.benefitslistText3')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-area BrandFaq">
            <div className="container">
              <div class="heading-bx text-center">
                <h6 class="title-ext text-primary">{t('header-area.swiss')}</h6>
                <h2 class="title mb-0">{t('header-area.faq')}</h2>
                <p>
                    {t('header-area.faqtext')}
                </p>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Accordion className="accordion ttr-accordion style1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <i className="fa fa-question-circle"></i>
                            {t('BreakCaliperPainting.question1')}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0">
                            {t('BreakCaliperPainting.answer1')}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <i className="fa fa-question-circle"></i>
                            {t('BreakCaliperPainting.question2')}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0">
                            {t('BreakCaliperPainting.answer2')}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="col-lg-6">
                  <Accordion className="accordion ttr-accordion style1">
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <i className="fa fa-question-circle"></i>
                            {t('BreakCaliperPainting.question3')}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0">
                            {t('BreakCaliperPainting.answer3')}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </section>

          <ScheduleServiceSection />
          <TestimonialSection />
          <ChooseUsSection />
          <ServicesSection />
          <Footer />
      </>
    );

}

export default BreakCaliperPainting;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Layout
import Header from "../../../layout/header";
import Footer from "../../../layout/footer";

// Elements
import WidgetBrochure from "../../../elements/widget/widget-brochure";
import WidgetHelp from "../../../elements/widget/widget-help";
import ScheduleServiceSection from "../../../elements/schedule-service";
import TestimonialSection from "../../../elements/testimonial";
import ChooseUsSection from "../../../elements/choose-us";
import ServicesSection from "../../../elements/services-1";

// Images
import bnrImg from "../../../../images/services/ACRepair.webp";
import serviceSectionPic3 from "../../../../images/service-section/pic3.jpg";

function ACRepair() {
  const { t } = useTranslation();
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
                <span>{t("acRepairservice.titleH1-1")}</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item text-uppercase">
                    <Link to="/">
                      <i className="las la-home"></i>
                      {t("header-area.home")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-uppercase">
                    <Link to="/services">{t("header-area.services")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {" "}
                    {t("acRepairservice.titleH1-1")}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="section-area section-sp1 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-5">
                <aside className="sticky-top pb-1">
                  <WidgetBrochure />

                  <WidgetHelp />
                </aside>
              </div>
              <div className="col-lg-8 col-md-7">
                <div className="mb-10">
                  <div className="head-text mb-30">
                    <h4 className="title mb-10">
                      {t("acRepairservice.titleH4-2")}
                    </h4>
                    <p className="mb-15">{t("acRepairservice.paraText1")}</p>
                    <p className="mb-15">{t("acRepairservice.paraText2")}</p>
                  </div>
                  <div className="row">
                    <div className="col-xl-8">
                      <div className="progress-area mb-30">
                        <div className="progress-box style1 appear">
                          <div className="progress-info">
                            <h6 className="title">
                              {t("acRepairservice.slist1")}
                            </h6>
                            <h6 className="progress-value">100%</h6>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar bg-primary"
                              style={{ width: "100%" }}
                            ></div>
                          </div>
                        </div>
                        <div className="progress-box style1 appear">
                          <div className="progress-info">
                            <h6 className="title">
                              {t("acRepairservice.slist2")}
                            </h6>
                            <h6 className="progress-value">100%</h6>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar bg-primary"
                              style={{ width: "100%" }}
                            ></div>
                          </div>
                        </div>
                        <div className="progress-box style1 appear">
                          <div className="progress-info">
                            <h6 className="title">
                              {t("acRepairservice.slist3")}
                            </h6>
                            <h6 className="progress-value">100%</h6>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar bg-primary"
                              style={{ width: "100%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <h5 class="title mb-15 text-uppercase">
                        {t("acRepairservice.titleH5-3")}
                      </h5>
                      <ul className="list-check-squer">
                        <li>{t("acRepairservice.servicelist1")}</li>
                        <li>{t("acRepairservice.servicelist2")}</li>
                        <li>{t("acRepairservice.servicelist3")}</li>
                        <li>{t("acRepairservice.servicelist4")}</li>
                      </ul>
                      <p className="mb-15">{t("acRepairservice.paraText3")}</p>
                    </div>
                  </div>
                </div>
                <div className="head-text mb-30">
                  <h4 className="title mb-15">
                    {t("acRepairservice.titleH6-4")}
                  </h4>
                  <p className="mb-15">{t("acRepairservice.paraText4")}</p>
                  <p className="mb-15">{t("acRepairservice.paraText5")}</p>
                  <h4 className="title mb-10">
                    {t("acRepairservice.titleH4-5")}
                  </h4>
                  <p className="mb-10">{t("acRepairservice.paraText6")}</p>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <ul className="list-check-squer mb-0">
                      <li>{t("acRepairservice.list1")}</li>
                      <li>{t("acRepairservice.list2")}</li>
                      <li>{t("acRepairservice.list3")} </li>
                      <li>{t("acRepairservice.list4")}</li>
                      <li>{t("acRepairservice.list5")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section-area section-sp2 ServiceBox1">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary arhide">
              {t("acRepairservice.titleH6-6")}{" "}
            </h6>
            <h2 class="title mb-0">{t("acRepairservice.titleH2-7")}</h2>
          </div>
          <div className="SerGridWrap">
            <div className="row mb-10 justify-content-center">
              <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                <div className="counter-style-1">
                  <div className="text-secondry">
                    <h2 className="text-primary">
                      <span className="counter">
                        <i class="flaticon-car-4"></i>
                      </span>
                    </h2>
                  </div>
                  <h5 className="counter-text">
                    {t("acRepairservice.chooselist1")}{" "}
                  </h5>
                  <p>{t("acRepairservice.chooselistText1")}</p>
                </div>
              </div>
              <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                <div className="counter-style-1">
                  <div className="text-secondry">
                    <h2 className="text-primary">
                      <span className="counter">
                        <i class="flaticon-car-4"></i>
                      </span>
                    </h2>
                  </div>
                  <h5 className="counter-text">
                    {t("acRepairservice.chooselist2")}
                  </h5>
                  <p>{t("acRepairservice.chooselistText2")}</p>
                </div>
              </div>
              <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                <div className="counter-style-1">
                  <div className="text-secondry">
                    <h2 className="text-primary">
                      <span className="counter">
                        <i class="flaticon-car-4"></i>
                      </span>
                    </h2>
                  </div>
                  <h5 className="counter-text">
                    {t("acRepairservice.chooselist3")}
                  </h5>
                  <p>{t("acRepairservice.chooselistText3")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-area BrandFaq">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary">{t("header-area.swiss")}</h6>
            <h2 class="title mb-0">{t("header-area.faq")}</h2>
            <p>{t("header-area.faqtext")}</p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("acRepairservice.question1")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("acRepairservice.answer1")}</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("acRepairservice.question2")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("acRepairservice.answer2")}</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("acRepairservice.question3")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("acRepairservice.answer3")}</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("acRepairservice.question4")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("acRepairservice.answer4")}</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("acRepairservice.question5")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("acRepairservice.answer5")}</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <ScheduleServiceSection />
      <TestimonialSection />
      <ChooseUsSection />
      <ServicesSection />
      <Footer />
    </>
  );
}

export default ACRepair;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Images
import pic6 from "../../images/about/pic6.jpg";

function ChooseUsSection() {

	const { t } = useTranslation();  
    return (
      <>
        <section className="section-area bg-white section-sp1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-30">
                <div className="choose-media">
                  <img src={pic6} alt="" />
                </div>
              </div>
              <div className="col-lg-6 mb-30">
                <div className="heading-bx mb-30">
                  <h6 className="title-ext text-primary">
                  {t('home-whychoose-section.title')}
                  </h6>
                  <h2 className="title mb-0">{t('home-whychoose-section.title2')}</h2>
                  <p>{t('home-whychoose-section.sub-title-1')}</p>
                  <p>{t('home-whychoose-section.sub-title-2')}</p>
                </div>
                <ul class="list-check-squer mb-20 mb-sm-30">
                  <li>{t('home-whychoose-section.list1')}</li>
                  <li>{t('home-whychoose-section.list2')}</li>
                  <li>{t('home-whychoose-section.list3')}</li>
                </ul>
                
                {/* <Link to="/about-us" className="btn btn-primary">
                  READ MORE
                </Link> */}
              </div>
            </div>
          </div>
        </section>
      </>
    );

}

export default ChooseUsSection;
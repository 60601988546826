import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

// Layout
import Header from "../../../layout/header";
import Footer from "../../../layout/footer";

// Elements
import WidgetBrochure from "../../../elements/widget/widget-brochure";
import WidgetHelp from "../../../elements/widget/widget-help";
import ScheduleServiceSection from "../../../elements/schedule-service";
import TestimonialSection from "../../../elements/testimonial";
import ChooseUsSection from "../../../elements/choose-us";
import ServicesSection from "../../../elements/services-1";

// Images
import bnrImg from "../../../../images/services/ElectricCarRepair.webp";
import serviceSectionPic3 from "../../../../images/service-section/pic3.jpg";

function CarRecovery() {

	const { t } = useTranslation();
    return (
      <>

		<Header />
        <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
                <span>{t("carrecoveryservice.titleH1-1")}</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item text-uppercase">
                    <Link to="/">
                      <i className="las la-home"></i>
                      {t("header-area.home")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-uppercase">
                    <Link to="/services">{t("header-area.services")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {" "}
                    {t("carrecoveryservice.titleH1-1")}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="section-area section-sp1 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-5">
                <aside className="sticky-top pb-1">
                  <WidgetBrochure />

                  <WidgetHelp />
                </aside>
              </div>
              <div className="col-lg-8 col-md-7">
                <div className="mb-10">
                  <div className="head-text mb-30">
                    <h4 className="title mb-10">
                      {t("carrecoveryservice.titleH4-1")}
                    </h4>
                    <p className="mb-15">
                      {t("carrecoveryservice.paraText1")}
                    </p>
                    <p className="mb-15">
                      {t("carrecoveryservice.paraText2")}
                    </p>
                    <p className="mb-15">
                      {t("carrecoveryservice.paraText3")}
                    </p>
                    <h4 className="title mb-10">
                      {t("carrecoveryservice.titleH4-3")}
                    </h4>
                    
                  </div>
                  <div className="row head-text">
                    <div className="col-xl-12">
                      <h5 class="title mb-15 text-uppercase">
                        {t("carrecoveryservice.titleH5-4")}
                      </h5>
					  <p className="mb-15">
						{t("carrecoveryservice.paraText4")}
					  </p>

					  <h5 class="title mb-15 text-uppercase">
                        {t("carrecoveryservice.titleH5-5")}
                      </h5>
					  <p className="mb-15">
						{t("carrecoveryservice.paraText5")}
					  </p>

					  <h5 class="title mb-15 text-uppercase">
                        {t("carrecoveryservice.titleH5-6")}
                      </h5>
					  <p className="mb-15">
						{t("carrecoveryservice.paraText6")}
					  </p>

					  <h5 class="title mb-15 text-uppercase">
                        {t("carrecoveryservice.titleH5-7")}
                      </h5>
					  <p className="mb-15">
						{t("carrecoveryservice.paraText7")}
					  </p>
                      
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <section className="section-area BrandFaq">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary">{t("header-area.swiss")}</h6>
            <h2 class="title mb-0">{t("header-area.faq")}</h2>
            <p>{t("header-area.faqtext")}</p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("carrecoveryservice.question1")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      {t("carrecoveryservice.answer1")}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("carrecoveryservice.question2")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      {t("carrecoveryservice.answer2")}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("carrecoveryservice.question3")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      {t("carrecoveryservice.answer3")}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <ScheduleServiceSection />
      <TestimonialSection />
      <ChooseUsSection />
      <ServicesSection />
      <Footer />
      </>
    );

}

export default CarRecovery;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Layout
import Header from "../../../layout/header";
import Footer from "../../../layout/footer";

// Elements
import WidgetBrochure from "../../../elements/widget/widget-brochure";
import WidgetHelp from "../../../elements/widget/widget-help";
import ScheduleServiceSection from "../../../elements/schedule-service";
import TestimonialSection from "../../../elements/testimonial";
import ChooseUsSection from "../../../elements/choose-us";
import ServicesSection from "../../../elements/services-1";

// Images
import bnrImg from "../../../../images/services/EngineCoolingSystem.webp";
import serviceSectionPic3 from "../../../../images/service-section/pic3.jpg";

function EnginCoolingSystemRepair() {
  const { t } = useTranslation();
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
                <span>{t("enginecoolingsystemservice.titleH1-1")}</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item text-uppercase">
                    <Link to="/">
                      <i className="las la-home"></i>{t("header-area.home")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-uppercase">
                    <Link to="/services">{t("header-area.services")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  {t("enginecoolingsystemservice.titleH1-1")}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="section-area section-sp1 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-5">
                <aside className="sticky-top pb-1">
                  <WidgetBrochure />

                  <WidgetHelp />
                </aside>
              </div>
              <div className="col-lg-8 col-md-7">
                <div className="mb-10">
                  <div className="head-text mb-30">
                    <h4 className="title mb-10">
                      {t("enginecoolingsystemservice.titleH4-1")}
                    </h4>
                    <p className="mb-15">
                      {t("enginecoolingsystemservice.paraText1")}{" "}
                    </p>
                    <p className="mb-15">
                      {t("enginecoolingsystemservice.paraText2")}
                    </p>
                    <p className="mb-15">
                      {t("enginecoolingsystemservice.paraText3")}
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <h5 class="title mb-15 text-uppercase">
                        {t("enginecoolingsystemservice.titleH5-1")}
                      </h5>
                      <p className="mb-15">
                        {t("enginecoolingsystemservice.paraText4")}
                      </p>
                      <h5 class="title mb-15 text-uppercase">
                        {t("enginecoolingsystemservice.titleH5-2")}
                      </h5>
                      <ul className="list-check-squer">
                        <li>{t("enginecoolingsystemservice.slist1")}</li>
                        <li>{t("enginecoolingsystemservice.slist2")}</li>
                        <li>{t("enginecoolingsystemservice.slist3")}</li>
                        <li>{t("enginecoolingsystemservice.slist4")}</li>
                        <li>{t("enginecoolingsystemservice.slist5")}</li>
                        <li>{t("enginecoolingsystemservice.slist6")}</li>
                      </ul>
                      <h5 class="title mb-15 text-uppercase">
                        {t("enginecoolingsystemservice.titleH5-3")}
                      </h5>
                      <ul className="list-check-squer">
                        <li>{t("enginecoolingsystemservice.servicelist1")}</li>
                        <li>{t("enginecoolingsystemservice.servicelist2")}</li>
                        <li>{t("enginecoolingsystemservice.servicelist3")}</li>
                        <li>{t("enginecoolingsystemservice.servicelist4")}</li>
                        <li>{t("enginecoolingsystemservice.servicelist5")}</li>
                        <li>{t("enginecoolingsystemservice.servicelist6")}</li>
                        <li>{t("enginecoolingsystemservice.servicelist7")}</li>
                      </ul>
                      <p className="mb-15">
                        {t("enginecoolingsystemservice.paraText5")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section-area section-sp2 ServiceBox1">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary arhide">
              {t("enginecoolingsystemservice.titleH6-3")}
            </h6>
            <h2 class="title mb-0 text-uppercase">
              {t("enginecoolingsystemservice.titleH2-4")}
            </h2>
          </div>
          <div className="SerGridWrap">
            <div className="row mb-10 justify-content-center">
              <div className="col-xl-6 col-sm-6 col-6 SerGrid1">
                <div className="counter-style-1">
                  <div className="text-secondry">
                    <h2 className="text-primary">
                      <span className="counter">
                        <i class="flaticon-car-4"></i>
                      </span>
                    </h2>
                  </div>
                  <h5 className="counter-text">
                    {t("enginecoolingsystemservice.servicelist8")}
                  </h5>
                  <p>{t("enginecoolingsystemservice.servicelisttext8")}</p>
                </div>
              </div>
              <div className="col-xl-6 col-sm-6 col-6 SerGrid1">
                <div className="counter-style-1">
                  <div className="text-secondry">
                    <h2 className="text-primary">
                      <span className="counter">
                        <i class="flaticon-car-4"></i>
                      </span>
                    </h2>
                  </div>
                  <h5 className="counter-text">
                    {t("enginecoolingsystemservice.servicelist9")}
                  </h5>
                  <p>{t("enginecoolingsystemservice.servicelisttext9")}</p>
                </div>
              </div>
              <div className="col-xl-6 col-sm-6 col-6 SerGrid1">
                <div className="counter-style-1">
                  <div className="text-secondry">
                    <h2 className="text-primary">
                      <span className="counter">
                        <i class="flaticon-car-4"></i>
                      </span>
                    </h2>
                  </div>
                  <h5 className="counter-text">
                    {t("enginecoolingsystemservice.servicelist10")}
                  </h5>
                  <p>{t("enginecoolingsystemservice.servicelisttext10")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-area BrandFaq">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary">{t("header-area.swiss")}</h6>
            <h2 class="title mb-0">{t("header-area.faq")}</h2>
            <p>
            {t("header-area.faqtext")}
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    Why is my car engine overheating?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      Car engines may overheat due to coolant issues, water pump
                      failure, thermostat issues, radiator failure or any other.
                      So if the engine overheating occurs it is essential to
                      visit the service center at the earliest to avoid
                      catastrophic damages.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("enginecoolingsystemservice.question1")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      {t("enginecoolingsystemservice.answer1")}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("enginecoolingsystemservice.question1")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      {t("enginecoolingsystemservice.answer1")}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <ScheduleServiceSection />
      <TestimonialSection />
      <ChooseUsSection />
      <ServicesSection />
      <Footer />
    </>
  );
}

export default EnginCoolingSystemRepair;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

// Layout
import Header from "../../layout/header";
import Footer from "../../layout/footer";

// Elements
import WidgetBrochure from "../../elements/widget/widget-brochure";
import WidgetHelp from "../../elements/widget/widget-help";
import ScheduleServiceSection from "../../elements/schedule-service";
import TestimonialSection from "../../elements/testimonial";
import ChooseUsSection from "../../elements/choose-us";
import BrandServices from "../../elements/widget/BrandServices";

// Images
import bnrImg from "../../../images/carbrands/Ford.webp";
import serviceSectionPic3 from "../../../images/brandsthumb/FORD body Image.webp";

function Ford() {

  const { t } = useTranslation();

    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div
            className="page-banner"
            style={{ backgroundImage: "url(" + bnrImg + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>
                  <span>{t("brandford.headtitle")}</span>
                </h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="las la-home"></i>{t('header-area.home')}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    {t("brandford.name")}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <div className="section-area section-sp1 bg-white">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-5">
                  <aside className="sticky-top pb-1">
                    <WidgetBrochure />

                    <WidgetHelp />
                  </aside>
                </div>
                <div className="col-lg-8 col-md-7">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="ttr-media mb-30">
                        <img src={serviceSectionPic3} alt="" />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <h4 className="title mb-10">
                        {t("brandford.benefitsH1")}
                      </h4>
                      <p className="mb-20">
                        {t("brandford.bsubtext")}
                        
                      </p>
                      <ul className="list-check-squer mb-30">
                        <li>{t("brandford.blist1")}</li>
                        <li>{t("brandford.blist2")}</li>
                        <li>{t("brandford.blist3")}</li>
                        <li>{t("brandford.blist4")}</li>
                        <li>{t("brandford.blist5")}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="mb-10 ContentBox2">
                    <div className="head-text mb-30">
                      <h4 className="title mb-15">
                        {t("brandford.headtext1")}
                      </h4>
                      <h5 className="mb-15">
                        <b>{t("brandford.subhead1")}</b>
                      </h5>
                      <p className="mb-15">{t("brandford.content1")}</p>
                      <p className="mb-15">{t("brandford.content2")}</p>
                      <p className="mb-15">{t("brandford.content3")}</p>
                      <h5 className="mb-15">
                        <b>{t("brandford.subhead2")}</b>
                      </h5>
                      <p className="mb-15">{t("brandford.content4")}</p>
                    </div>
                    <div className="row">
                      <h5 className="mb-15">
                        <b>{t("brandford.subhead3")}</b>
                      </h5>
                      <div className="col-xl-12">
                        <ul className="list-check-squer">
                          <li>{t("brandford.slist1")}</li>
                          <li>{t("brandford.slist2")}</li>
                          <li>{t("brandford.slist3")}</li>
                          <li>{t("brandford.slist4")}</li>
                          <li>{t("brandford.slist5")}</li>
                          <li>{t("brandford.slist6")}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="head-text mb-30">
                    <h4 className="title mb-10">
                      {t("brandford.headtext2")}
                    </h4>
                    <p className="mb-15">{t("brandford.content5")}</p>
                    <p className="mb-15">{t("brandford.content6")}</p>
                    <p className="mb-15">{t("brandford.content7")}</p>
                    <p className="mb-15">{t("brandford.content8")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-area section-sp2 ServiceBox1 IconBox1">
          <div className="container">
            <div class="heading-bx text-center">
              <h6 class="title-ext text-primary arhide">
                {t("brandford.servicessubhead")}
              </h6>
              <h2 class="title mb-0">{t("brandford.serviceshead")}</h2>
              <p>{t("brandford.servicescontent")}</p>
            </div>
            
            <BrandServices brname={'ford'} />

          </div>
        </section>
        <section className="section-area BrandFaq">
          <div className="container">
            <div class="heading-bx text-center">
              <h6 class="title-ext text-primary">{t('header-area.swiss')}</h6>
              <h2 class="title mb-0">{t('header-area.faq')}</h2>
              <p>
              {t('header-area.faqtext')}
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Accordion className="accordion ttr-accordion style1">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <i className="fa fa-question-circle"></i>
                      {t("brandford.question1")}
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{t("brandford.answer1")}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <i className="fa fa-question-circle"></i>
                      {t("brandford.question2")}
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{t("brandford.answer2")}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-lg-6">
                <Accordion className="accordion ttr-accordion style1">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <i className="fa fa-question-circle"></i>
                      {t("brandford.question3")}
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{t("brandford.answer4")}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </section>

        <ScheduleServiceSection />
        <TestimonialSection />
        <ChooseUsSection />
        <Footer />
      </>
    );
  
}

export default Ford;

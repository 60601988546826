import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Layout
import Header from "../../../layout/header";
import Footer from "../../../layout/footer";

// Elements
import WidgetBrochure from "../../../elements/widget/widget-brochure";
import WidgetHelp from "../../../elements/widget/widget-help";
import ScheduleServiceSection from "../../../elements/schedule-service";
import TestimonialSection from "../../../elements/testimonial";
import ChooseUsSection from "../../../elements/choose-us";
import ServicesSection from "../../../elements/services-1";

// Images
import bnrImg from "../../../../images/services/ClassicCarRestoration.webp";
import serviceSectionPic3 from "../../../../images/service-section/pic3.jpg";

function SuspensionRepair() {
  const { t } = useTranslation();
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
                <span>{t("suspensionepairservice.titleH1-1")}</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item text-uppercase">
                    <Link to="/">
                      <i className="las la-home"></i>{t("header-area.home")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-uppercase">
                    <Link to="/services">{t("header-area.services")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {" "}
                    {t("suspensionepairservice.titleH1-1")}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="section-area section-sp1 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-5">
                <aside className="sticky-top pb-1">
                  <WidgetBrochure />

                  <WidgetHelp />
                </aside>
              </div>
              <div className="col-lg-8 col-md-7">
                <div className="mb-10">
                  <div className="head-text mb-30">
                    <h4 className="title mb-10">
                      {t("suspensionepairservice.titleH4-1")}
                    </h4>
                    <p className="mb-15">{t("suspensionepairservice.paraText1")}</p>
                    <p className="mb-15">{t("suspensionepairservice.paraText2")}</p>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <h5 class="title mb-15 text-uppercase">
                        {t("suspensionepairservice.titleH5-1")}
                      </h5>
                      <ul className="list-check-squer">
                        <li>{t("suspensionepairservice.slist1")}</li>
                        <li>{t("suspensionepairservice.slist2")}</li>
                        <li>{t("suspensionepairservice.slist3")}.</li>
                        <li>{t("suspensionepairservice.slist4")}</li>
                        <li>{t("suspensionepairservice.slist5")}</li>
                        <li>{t("suspensionepairservice.slist6")}</li>
                      </ul>
                      <p className="mb-15">
                        {t("suspensionepairservice.paraText3")}
                      </p>
                      <h5 class="title mb-15 text-uppercase">
                        {t("suspensionepairservice.titleH5-2")}
                      </h5>
                      <ul className="list-check-squer">
                        <li>{t("suspensionepairservice.slist7")}</li>
                        <li>{t("suspensionepairservice.slist8")}</li>
                        <li>{t("suspensionepairservice.slist9")}</li>
                        <li>{t("suspensionepairservice.slist10")}</li>
                        <li>{t("suspensionepairservice.slist11")}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="head-text mb-30">
                  <h4 className="title mb-15">
                    {t("suspensionepairservice.titleH4-2")}
                  </h4>
                  <p className="mb-15">{t("suspensionepairservice.paraText4")}</p>
                  <p className="mb-15">{t("suspensionepairservice.paraText5")}</p>
                  <p className="mb-10">{t("suspensionepairservice.paraText6")}</p>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="title mb-15 text-uppercase">
                      {t("suspensionepairservice.titleH4-3")}
                    </h4>
                    <ul className="list-check-squer mb-0">
                      <li>{t("suspensionepairservice.slist12")}</li>
                      <li>{t("suspensionepairservice.slist13")}</li>
                      <li>{t("suspensionepairservice.slist14")} </li>
                      <li>{t("suspensionepairservice.slist15")}</li>
                      <li>{t("suspensionepairservice.slist16")}</li>
                      <li>{t("suspensionepairservice.slist17")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section-area section-sp2 ServiceBox1">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary arhide">
              {t("suspensionepairservice.titleH6-3")}
            </h6>
            <h2 class="title mb-0">{t("suspensionepairservice.titleH2-4")}</h2>
          </div>
          <div className="SerGridWrap">
            <div className="row mb-10 justify-content-center">
              <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                <div className="counter-style-1">
                  <div className="text-secondry">
                    <h2 className="text-primary">
                      <span className="counter">
                        <i class="flaticon-car-4"></i>
                      </span>
                    </h2>
                  </div>
                  <h5 className="counter-text">
                    {t("suspensionepairservice.servicelist1")}{" "}
                  </h5>
                  <p>{t("suspensionepairservice.servicelisttext1")}</p>
                </div>
              </div>
              <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                <div className="counter-style-1">
                  <div className="text-secondry">
                    <h2 className="text-primary">
                      <span className="counter">
                        <i class="flaticon-car-4"></i>
                      </span>
                    </h2>
                  </div>
                  <h5 className="counter-text">
                    {t("suspensionepairservice.servicelist2")}
                  </h5>
                  <p>{t("suspensionepairservice.servicelisttext2")}</p>
                </div>
              </div>
              <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                <div className="counter-style-1">
                  <div className="text-secondry">
                    <h2 className="text-primary">
                      <span className="counter">
                        <i class="flaticon-car-4"></i>
                      </span>
                    </h2>
                  </div>
                  <h5 className="counter-text">
                    {t("suspensionepairservice.servicelist3")}
                  </h5>
                  <p>{t("suspensionepairservice.servicelisttext3")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-area BrandFaq">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary">{t("header-area.swiss")}</h6>
            <h2 class="title mb-0">{t("header-area.faq")}</h2>
            <p>
            {t("header-area.faqtext")}
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("suspensionepairservice.question1")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("suspensionepairservice.answer1")}</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("suspensionepairservice.question2")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("suspensionepairservice.answer2")}</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("suspensionepairservice.question3")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("suspensionepairservice.answer3")}</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("suspensionepairservice.question4")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("suspensionepairservice.answer4")}</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <ScheduleServiceSection />
      <TestimonialSection />
      <ChooseUsSection />
      <ServicesSection />
      <Footer />
    </>
  );
}

export default SuspensionRepair;
